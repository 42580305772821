export const isClickedOnInput = event => {
  console.log(event.target.classList);
  return [
    'td-input',
    'btn',
    'custom-control-label',
    'driver-input-wrapper',
    'driver-input-id'
  ].some(className => event.target.classList.contains(className));
};

export const ContextMenuOptions = t => [
  {
    name: t('contextMenu.detailInfo'),
    slug: 'detail-info-modal',
    index: 0
  },
  {
    name: t('contextMenu.billingInfo'),
    slug: 'billing-info-modal',
    index: 1
  },
  {
    name: t('contextMenu.document'),
    slug: 'document-modal',
    index: 2
  },
  {
    name: t('contextMenu.memo'),
    slug: 'memo-modal',
    index: 3
  },
  {
    name: t('contextMenu.tracking'),
    slug: 'tracking-modal',
    index: 4
  }
];

export interface DoFile {
  filename: string;
  body?: any;
}

export interface CfFile {
  filename: string;
  body?: any;
}

export interface OtherFile {
  filename?: any;
  body?: any;
}

export interface WorkOrderEarlyWarning {
  warningNumber: string;
  containerNumber: string;
  date: string;
  orderNumber: string;
  category: string;
  warningLevel: string;
  warningIssue: string;
}

export interface WorkOrder {
  date: string;
  acctId: string;
  clientId?: any;
  createdBy?: any;
  category: string;
  orderNumber: string;
  orderPrefix?: any;
  billTo: string;
  masterBillOfLading: string;
  houseBillOfLading?: any;
  referenceNumber: string;
  poNumber: string;
  deliveryNumber: string;
  containerNumber: string;
  containerSize: string;
  containerType: string;
  shippingLine: string;
  vessel: string;
  chassisNumber: string;
  chassisPool: string;
  wms: boolean;
  eta: string;
  avaDsc: boolean;
  avaObl: boolean;
  avaCus: boolean;
  avaOth: boolean;
  lastFreeDay: string;
  freeDays: number;
  freeDaysType: string;
  seal: string;
  commodity: string;
  weight: string;
  quantity: string;
  unit: string;
  sendTo: string;
  remarks: string;
  pulloutLocation: string;
  pulloutSchedule: string;
  deliveryLocation: string;
  deliverySchedule: string;
  returnLocation: string;
  returnSchedule: string;
  overHeight: boolean;
  overWeight: boolean;
  haz: boolean;
  hot: boolean;
  dropLive: string;
  bondEntry: string;
  do: DoFile;
  cf: CfFile;
  other: OtherFile;
  bookingNumberMemo?: any;
}

export interface AccountReceivables {
  acctId: string;
  date?: any;
  billTo: string;
  billToName?: any;
  code: string;
  name?: any;
  rate?: any;
  quantity: number;
  amount: number;
  invoiceNumber?: any;
  ck?: any;
  remark?: any;
  id?: any;
}

export interface AccountPayable {
  acctId?: string;
  date?: string;
  type?: string;
  driverCode?: string;
  driverName?: string;
  division: string;
  code?: string;
  name?: any;
  amount?: number | string;
  rcvInvoice?: any;
  ck?: any;
  remark?: any;
  id?: any;
  invoiceAmount?: number | string;
  paidAmount?: string;
  disabled?: boolean;
  dummyCode?: boolean;
}
