import { ToastHelper } from './../../../utils/toast.util';
export class ValidationService {
  validationRules: {};

  constructor(validationRules) {
    this.validationRules = validationRules;
  }

  checkItemValidity(item: any, itemName: string) {
    let answer = null;

    this.validationRules[itemName].some(rule => {
      switch (rule.name) {
        case 'notUnknown':
          if (!item) {
            answer = false;
          }
          break;
        case 'notEmptyString':
          if (item?.trim() === '') {
            answer = false;
          }
          break;
        case 'regular':
          if (item && !item.match(new RegExp(rule.value))) {
            answer = false;
          }
          break;
      }

      if (answer === false) {
        return true;
      }
      return false;
    });

    return answer;
  }

  checkAllItemsValidity(obj) {
    for (const rule in this.validationRules) {
      if (this.checkItemValidity(obj[rule], rule) === false) {
        ToastHelper.show(
          'Error',
          `${rule} cant be ${obj[rule]}`,
          5000,
          'danger'
        );
        return false;
      }
    }

    return true;
  }

  validationClass(item: any, itemName: string) {
    return this.checkItemValidity(item, itemName) !== null
      ? ' is-invalid-cell'
      : '';
  }
}

export const stringCommonRule = [
  { name: 'notUnknown' },
  { name: 'notEmptyString' }
];
